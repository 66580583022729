import { routes as associate } from '@/modules/associate'
import { routes as associateAdministrator } from '@/modules/associateAdministrator'
import { routes as congress } from '@/modules/congress'
import { routes as congressAdministrator } from '@/modules/congressAdministrator'
import { routes as course } from '@/modules/course'
import { routes as courseAdministrator } from '@/modules/courseAdministrator'
import { routes as ecommerce } from '@/modules/ecommerce'
import { routes as home } from '@/modules/home'
import { routes as live } from '@/modules/live'
import { routes as eventList } from '@/modules/eventList'
import { routes as eventsAdministrator } from '@/modules/eventsAdministrator'
import { routes as eventAdministratorList } from '@/modules/eventAdministratorList'
import { routes as eventLivesList } from '@/modules/eventLivesList'
import { routes as eventsAdministratorParticipants } from '@/modules/eventsAdministratorParticipants'
import { routes as eventsAdministratorParticipantsAprovation } from '@/modules/eventsAdministratorParticipantsAprovation'
import { routes as liveAdministrator } from '@/modules/liveAdministrator'
import { routes as magazine } from '@/modules/magazine'
import { routes as magazineAdministrator } from '@/modules/magazineAdministrator'
import { routes as payment } from '@/modules/payment'
import { routes as podcast } from '@/modules/podcast'
import { routes as podcastAdministrator } from '@/modules/podcastAdministrator'
import { routes as profile } from '@/modules/profile'
import { routes as ranking } from '@/modules/ranking'
import { routes as statute } from '@/modules/statute'
import { routes as usersAdministrator } from '@/modules/usersAdministrator'
import { routes as auth } from '@/modules/auth'
import { routes as error } from '@/modules/error'
import { routes as homeAdministrator } from '@/modules/administrator'
import { routes as vote } from '@/modules/vote'
import { routes as raffle } from '@/modules/raffle'
import { routes as contentCategory } from '@/modules/contentCategory'
import { routes as AdmininstarLogin } from '@/modules/loginAdministrator'
import { routes as personalizar } from '@/modules/personalizar'
import { routes as gamificacao } from '@/modules/ranking'
import { routes as modulos } from '@/modules/modulos'
import { routes as patrocinadoresAdministrator } from '@/modules/patrocinadoresAdministrator'

export default [
  ...associate,
  ...associateAdministrator,
  ...ranking,
  ...profile,
  ...congress,
  ...congressAdministrator,
  ...course,
  ...courseAdministrator,
  ...payment,
  ...magazine,
  ...usersAdministrator,
  ...magazineAdministrator,
  ...statute,
  ...ecommerce,
  ...home,
  ...live,
  ...eventList,
  ...eventAdministratorList,
  ...eventsAdministrator,
  ...eventsAdministratorParticipants,
  ...eventLivesList,
  ...eventsAdministratorParticipantsAprovation,
  ...liveAdministrator,
  ...podcast,
  ...podcastAdministrator,
  ...auth,
  ...error,
  ...homeAdministrator,
  ...vote,
  ...raffle,
  ...contentCategory,
  ...AdmininstarLogin,
  ...personalizar,
  ...gamificacao,
  ...modulos,
  ...patrocinadoresAdministrator
]
